import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';


export function NavMenu() {
    const { slug} = useParams();
    let location = useLocation();

    const cssActive = 'bg-gray-200 text-gray-800';
    const cssNormal = 'text-gray-600 hover:text-gray-800 hover:bg-gray-100'


    return (
        <nav className="flex space-x-4 justify-center p-5">
                <a href={`/${slug}`}  className={'rounded-md px-3 py-2 text-sm font-medium ' + (location.pathname.indexOf('groupDescription') === -1 ? cssActive: cssNormal)}>Offene Jobs</a>
                {/* @if(activePage == "jobs"){
                    <a href="@JobGroupSlug" className="active">Offene Jobs</a>
                }else{
                    <a href="@JobGroupSlug">Offene Jobs</a>
                } */}
                <a href={'/'+slug+'/groupDescription'} className={'rounded-md px-3 py-2 text-sm font-medium ' + (location.pathname.indexOf('groupDescription') > -1 ? cssActive: cssNormal)} >Zum Betrieb</a>
                {/* @if(activePage == "groupDescription"){
                    <a href=@($"{JobGroupSlug}/groupDescription") className="active">Zum Betrieb</a>
        }else{
                <a href=@($"{JobGroupSlug}/groupDescription")>Zum Betrieb</a>
} */}
        </nav >
    )
}