import * as React from 'react';
import { useParams } from 'react-router-dom';
//import './../styles.scss';
import './../styles/tailwind.css';
import { Header } from '../components/header';
import { NavMenu } from '../components/navigation';
import { AppStateContext } from '../store/store';
import { DisplayGroup } from '../components/displaygroup';


export function GroupPage() {
    const { slug } = useParams();
    const context = React.useContext(AppStateContext);

    React.useEffect(() => {
        if (slug === undefined || slug === '') return;
        context.onFetchProperty(slug);
        context.onFetchJobs(slug);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug]);


    if (slug === undefined || slug === '') {
        return (
            <div>Loading ...</div>
        )
    }

    return (
        <div className='jobpage bg-gray-100 h-full pb-5'>
            <Header property={context.property} />
            <NavMenu />
            <div className='mx-auto w-full flex justify-center gap-5 px-3 md:p-0 md:w-5/6'>
                <DisplayGroup group={context.property} />
            </div>
            <footer></footer>
        </div>
    );
}