import * as React from 'react'
import { IPropertyDTO } from '../models/IProperty'
import { FaLocationDot } from 'react-icons/fa6'


interface IProps {
    property: IPropertyDTO | null
}
export function Header(p: IProps) {

    if (p.property == null) return <>"Loading ..."</>

    return (
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 sm:py-32 lg:px-8">
            <img src={p.property.BannerImage} alt="banner" className="absolute inset-0 -z-10 h-full w-full object-cover brightness-75" />

            <div className="mx-auto max-w-2xl text-center">

                <div className='bg-white mx-auto rounded size-24 md:size-32 flex-column align-center content-center p-3 md:p-5'>
                    <img alt='property icon' src={p.property.IconImage} className='w-full' />
                </div>
                <h2 className="text-4xl mt-2 font-bold tracking-tight text-white sm:text-6xl">{p.property.Name}</h2>
                <h4 className="mt-2 text-2xl leading-5 text-white">{p.property.SubTitle}</h4>
                <div className='mt-4 flex flex-row justify-center'>
                    <p className="text-white flex flex-column align-center content-center gap-2">
                        <span><FaLocationDot /></span>
                        <span> {p.property.Street} {p.property.StreetNumber} | {p.property.Zip} {p.property.City}</span>
                    </p>
                </div>
            </div>

        </div>
    )
}