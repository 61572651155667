import * as React from 'react'
import { AppStateContext } from '../store/store';
import { ApiCalls } from '../request/apicalls';
import { useParams } from 'react-router';
import { IFormData } from '../models/IFormData';
import { MailCheck, MailWarning } from 'lucide-react';


interface ISnackMessage {
    message: string,
    error: boolean
}

export function EmailForm() {
    const AppState = React.useContext(AppStateContext);
    const { slug, id } = useParams();


    const [errorMessage, setErrorMessage] = React.useState<string[]>([])
    const [privacy, setPrivacy] = React.useState<boolean>(false)
    const [formData, setFormData] = React.useState<IFormData>({
        FirstName: '',
        LastName: '',
        Email: '',
        InterestedIn: '',
        Phone: '',
        Experience: '',
        AdditionalQuestions: '',
        JobListingId: undefined
    })

    const [snackMessage, setSnackMessage] = React.useState<ISnackMessage | null>(null)

    React.useEffect(() => {
        if (id === undefined) setFormData({ ...formData, InterestedIn: '', JobListingId: undefined })

        if (AppState.jobs && AppState.jobs.length > 0) {
            let selectedJob = AppState.jobs?.filter(x => x.Id.toString() === id);
            if (selectedJob != null && selectedJob.length > 0) {
                setFormData({ ...formData, InterestedIn: selectedJob[0].Title, JobListingId: selectedJob[0].Id })
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, AppState.jobs])

    const updateFormData = (event: React.ChangeEvent<any>) => {
        setFormData({ ...formData, [event.target.name]: event.target.value })
    }

    const showSnack = (message: string, error: boolean) => {
        setSnackMessage({ message: message, error: error });
        setTimeout(() => {
            setSnackMessage(null);
        }, 4000)
    }

    const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
        if (slug === undefined) return;

        // reset error
        let errorMsg: string[] = []

        // prevent default
        event.preventDefault();

        // check if all fields are filled
        let isError = false;
        if (formData.FirstName === '') {
            errorMsg.push('Vorname');
            isError = true;
        }
        if (formData.LastName === '') {
            errorMsg.push('Nachname');
            isError = true;
        }
        if (formData.Email === '') {
            errorMsg.push('E-Mail');
            isError = true;
        }
        if (privacy === false) {
            errorMsg.push('Privacy');
            isError = true;
        }

        setErrorMessage(errorMsg)
        if (isError) return;

        // start POST request
        let apiRequest = new ApiCalls();
        apiRequest.submitJob(slug, formData).then((response) => {
            if (response.status === 200) {
                showSnack('Danke für deine Bewerbung! Wir melden uns in Kürze', false);
                setFormData({
                    FirstName: '',
                    LastName: '',
                    Email: '',
                    InterestedIn: '',
                    Phone: '',
                    Experience: '',
                    AdditionalQuestions: '',
                    JobListingId: formData.JobListingId
                })
                setPrivacy(false);
            } else {
                showSnack('Bewerbung konnte nicht abgeschickt werden', true);
            }
        }).catch((error) => {
            console.log(error)
            showSnack('Bewerbung konnte nicht abgeschickt werden', true);
        })

    }



    return (
        <form className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 rounded-lg shadow " onSubmit={(e) => submitForm(e)}>
            <div className='mb-5'>

                <p className="font-bold">Meine Bewerbung</p>
                <p className="text text-gray-500">Bitte sende uns deine Daten, wir werden uns anschließend gleich bei dir melden.</p>
            </div>
            {/* <EditForm Model="@formData" OnValidSubmit=@ValidFormSubmitted  OnInvalidSubmit=@InvalidFormSubmitted> */}
            {/* <DataAnnotationsValidator></DataAnnotationsValidator> */}
            <div className="grid grid-cols-2 grid-rows-2 gap-5 gap-y-3 mb-5">
                <InputText value={formData.FirstName} required={true} name='FirstName' placeholder="Vorname" onHandleChange={updateFormData} />
                {/* <ValidationMessage For="@(() => formData.FirstName)" className="errorMessage" /> */}
                <InputText value={formData.LastName} required={true} name='LastName' placeholder="Nachname" onHandleChange={updateFormData} />
                {/* <ValidationMessage For="@(() => formData.LastName)" className="errorMessage" /> */}
                <InputText value={formData.Email} required={true} name='Email' placeholder="E-Mail" onHandleChange={updateFormData} />
                <InputText value={formData.Phone} name='Phone' placeholder="Telefonnummer" onHandleChange={updateFormData} />
            </div>
            <div className='mb-3'>
                <label htmlFor="InterestedIn" className="block text-sm font-semibold leading-6 text-gray-900">Interessiert an</label>
                <InputText value={formData.InterestedIn} name='InterestedIn' placeholder="Interessiert an" onHandleChange={updateFormData} />
            </div>

            <div className='mb-3'>
                <label htmlFor="Experience" className="block text-sm font-semibold leading-6 text-gray-900">Arbeitserfahrung</label>
                <InputTextArea value={formData.Experience} name='Experience' placeholder="Arbeitserfahrung" onHandleChange={updateFormData} rows={5} />
            </div>
            <div className='mb-3'>
                <label htmlFor="AdditionalQuestions" className="block text-sm font-semibold leading-6 text-gray-900">Weitere Fragen</label>
                <InputTextArea value={formData.AdditionalQuestions} name='AdditionalQuestions' placeholder="Weitere Fragen" onHandleChange={updateFormData} rows={5} />
            </div>

            <div className="mb-3 flex items-start">
                <div className='flex h-6 items-center'>
                    <input type="checkbox" value={privacy ? 'checked' : ''} id="privacyProtection" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" onChange={() => setPrivacy(!privacy)} />
                </div>
                <div className='ml-3 text-sm leading-6'>
                    <label htmlFor="privacyProtection" >Ich akzeptiere die <a className='text-blue-600 underline' href={AppState.property?.PrivacyLink}>Datenschutzbestimmungen</a></label>
                </div>
            </div>

            {errorMessage.length > 0 &&
                <div className="px-5 py-3 border border-red-900 bg-red-400 rounded mb-3">
                    <p>Bitte füllen Sie folgende Felder aus: {errorMessage.join(', ')}</p>
                </div>
            }

            <button type="submit" className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" id="formSubmit">
                Bewerbung absenden
            </button>
            <SnackMassage data={snackMessage} />

        </form >
    )
}


interface IInputTextProps {
    placeholder: string,
    value: string,
    name: string,
    required?: boolean,
    type?: string,
    onHandleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}
function InputText(p: IInputTextProps) {

    let acutalType = 'text';
    if (p.type) {
        acutalType = p.type;
    }

    return (
        <input type={acutalType} name={p.name} required={p.required ?? false} className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 inputField' placeholder={p.placeholder} value={p.value} onChange={p.onHandleChange} />
    )
}
interface IInputTextAreaProps {
    placeholder: string,
    value: string,
    name: string,
    rows: number,
    onHandleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}
function InputTextArea(p: IInputTextAreaProps) {

    return (
        <textarea name={p.name} rows={p.rows} value={p.value} className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 multiLineInputField' placeholder={p.placeholder} onChange={p.onHandleChange} >{p.value}</textarea>
    )
}

interface ISnackMessageProps {
    data: ISnackMessage | null
}

// the snack component hrere
function SnackMassage(p: ISnackMessageProps) {

    if (p.data == null) return null;

    return (
        <div className={"fixed bottom-0 right-0 flex items-end px-4 py-6 sm:items-start sm:p-6"}>
            <div className={'px-5 py-3 rounded-lg flex flex-row gap-1 items-center justify-center border shadow-lg ring-1 ring-black ring-opacity-5 ' + (p.data.error ? 'bg-red-100 border-red-400' : 'bg-green-100 border-green-400')}>
                <span>
                    {p.data.error ? <MailWarning /> : <MailCheck />}
                </span>
                <p>{p.data.message}</p>
            </div>
        </div>
    )
}