import * as React from 'react'
import img from './../img/ionic-ios-close.svg'
import { useParams } from 'react-router-dom';



export function CloseButton() {
    const {slug} = useParams();
    
    return (
        <a className="absolute top-0 right-0 bg-red-800 rounded m-2 p-2 hidden closeButton" href={`/${slug}`} >
            <img src={img} alt='close button'  />
        </a>
    )
}