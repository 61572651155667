import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p style={{fontSize:300, padding:0, margin:0}}>
          ;)
        </p>
        <p>
          <br/>It seems you found an empty page.
        </p>
      </header>
    </div>
  );
}

export default App;
