import * as React from 'react';
import { IJobDTO } from '../models/IJob';
import { IPropertyDTO } from '../models/IProperty';
import { ApiCalls } from '../request/apicalls';



export interface IStoreState {
    jobs: Array<IJobDTO> | null;
    selectedJobId: number | null;
    selectedJob: IJobDTO | null;
    property: IPropertyDTO | null;
    loading: boolean;
    error: string;
}

export interface IAppState {
    data: IStoreState,
    property: IPropertyDTO | null,
    jobs: Array<IJobDTO> | null,
    onFetchProperty: (slug: string) => void
    onFetchJob: (slug: string, id: number) => void
    onFetchJobs: (slug: string) => void 
}

/**
 * App state contect provider with react createContext
 */
export const AppStateContext = React.createContext<IAppState>(
    {
        data: {} as IStoreState,
        property: null,
        jobs: null,
        onFetchJob: (slug: string, id: number) => console.log(id),
        onFetchJobs: (slug: string) => console.log(slug),
        onFetchProperty: (slug: string) => console.log(slug)
    });


/**
 * App State component where all the data and the fetch logic is placed
 * @param p 
 * @returns 
 */
export function AppState(p: { children: React.ReactNode }) {

    const [state, setState] = React.useState<IStoreState>({
        jobs: null,
        selectedJob: null,
        selectedJobId: null,
        property: null,
        loading: false,
        error: ''
    });
    const [stateProperty, setStateProperty] = React.useState<IPropertyDTO | null>(null);
    const [stateJobs, setStateJobs] = React.useState<Array<IJobDTO> | null>(null);

    const fetchSlug = (slug: string) => {
        console.log("fetch property")
        // set loading
        setState({ ...state, loading: true });
        // fetch data
        let apiCalls = new ApiCalls();
        apiCalls.fetchProperty(slug)
            .then((data) => {
                console.log("fetch property", data)
                setStateProperty(data.data);
                setState({ ...state, property: data.data, loading: false });
            }).catch((error: string) => {
                setState({ ...state, error: error, loading: false });
            })
    }

    const fetchJob = (slug:string, id: number) => {
        console.log("fetch job")
        // set loading
        setState({ ...state, loading: true });
        // fetch data
        let apiCalls = new ApiCalls();
        apiCalls.fetchJob(slug, id)
            .then((data) => {
                console.log("fetch job", data)
                setState({ ...state, selectedJob: data.data, loading: false, selectedJobId: id });
            }).catch((error: string) => {
                setState({ ...state, error: error, loading: false });
            })
    }

    const fetchJobs = (slug: string) => {
        console.log("fetch jobs")
        // set loading
        setState({ ...state, loading: true });
        // fetch data
        let apiCalls = new ApiCalls();
        apiCalls.fetchJobs(slug)
            .then((data) => {
                console.log("fetch jobs", data)
                setStateJobs(data.data);
                setState({ ...state, jobs: data.data, loading: false });
            }).catch((error: string) => {
                setState({ ...state, error: error, loading: false });
            })
    }



    let appState: IAppState = {
        data: state,
        property: stateProperty,
        jobs: stateJobs,
        onFetchJob: fetchJob,
        onFetchProperty: fetchSlug,
        onFetchJobs: fetchJobs
    }

    return (
        <AppStateContext.Provider value={appState}>
            {p.children}
        </AppStateContext.Provider>
    )

}