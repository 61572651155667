import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { JobPage } from './routes/jobpage';
import { AppState } from './store/store';
import { GroupPage } from './routes/groupdescription';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: ":slug",
    element: <JobPage />,
  },
  {
    path: ":slug/:id",
    element: <JobPage />
  },
  {
    path: ":slug/groupDescription",
    element: <GroupPage />
  }
]);

root.render(
  <AppState>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </AppState>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
