import * as React from 'react';


export function DisplayDateTime(p: { date: string | Date | null | undefined, showTime?: boolean, showDate?: boolean }) {
    if (p.date === undefined) return null;

    let d: Date;
    // return null
    if (p.date === null) return null;
    // pass the date
    if (p.date instanceof Date) {
        d = p.date;
    }
    // pass the date as a string
    else {
        d = new Date(p.date);
    }

    const month = d.getMonth();
    const day = d.getDate();
    const year = d.getFullYear();
    const hour = d.getHours();
    const minute = d.getMinutes();
    const ampm = hour >= 12 ? 'pm' : 'am';
    const hour12 = hour % 12;
    const hourStr = hour12 === 0 ? '12' : hour12.toString();
    const minuteStr = minute < 10 ? `0${minute}` : minute.toString();
    const dateStr = `${month}/${day}/${year}`;
    const timeStr = `${hourStr}:${minuteStr}${ampm}`;
    return (
        <span className="displayDateTime">
            {p.showDate &&
                <span className="date">{dateStr}</span>
            }
            {p.showTime &&
                <span className="time">{timeStr}</span>
            }
        </span>
    )
}