import iconCookHat from './img/cook-hat.svg'
import iconCookingPot from './img/cooking-pot.svg'
import iconFacialTreatment from './img/facial-treatment.svg'
import iconMaid from './img/maid.svg'
import iconReceptionBell from './img/reception-bell.svg'
import iconRoomService from './img/room-service.svg'
import iconUserWithPc from './img/user-in-front-of-computer.svg'
import iconWaiterDish from './img/waiter-dish.svg'
import iconWaiterGlas from './img/waiter-glas.svg'
import iconBaggage from './img/baggage.svg'
import iconBrick from './img/brick.svg'
import iconCar from './img/car.svg'
import iconCharts from './img/charts.svg'
import iconClipboard from './img/clipboard.svg'
import iconFlower from './img/flower.svg'
import iconHeater from './img/heater.svg'
import iconMountain from './img/mountain.svg'
import iconWebSymbol from './img/websymbol.svg'


export type JobIconsOptions = 
"CookHat" | 
"CookingPot" | 
"FacialTreatment" | 
"Maid" | 
"ReceptionBell" | 
"RoomService" | 
"UserWithPC" | 
"WaiterDish" | 
"WaiterGlas" |
"Baggage" |
"Brick" |
"Car" |
"Charts" |
"Clipboard" |
"Flower" |
"Heater" |
"Mountain" |
"WebSymbol"

type JobIconDictionary = {[key in JobIconsOptions]: string}

export const jobIconDictionary: JobIconDictionary = {
    "CookHat": iconCookHat,
    "CookingPot": iconCookingPot,
    "FacialTreatment": iconFacialTreatment,
    "Maid": iconMaid,
    "ReceptionBell": iconReceptionBell,
    "RoomService": iconRoomService,
    "UserWithPC": iconUserWithPc,
    "WaiterDish": iconWaiterDish,
    "WaiterGlas": iconWaiterGlas,
    "Baggage": iconBaggage,
    "Brick": iconBrick,
    "Car": iconCar,
    "Charts": iconCharts,
    "Clipboard": iconClipboard,
    "Flower": iconFlower,
    "Heater": iconHeater,
    "Mountain": iconMountain,
    "WebSymbol": iconWebSymbol
}

interface IProp {
    iconName: JobIconsOptions;
}
export function JobIcon(p: IProp) {
    let icon: string | null = null;

    if (p.iconName in jobIconDictionary) {
        icon = jobIconDictionary[p.iconName];
    }

    if (icon === null) return null;

    return (
        <img className='size-full' src={icon} alt={p.iconName} />
    )
}