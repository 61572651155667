import * as React from 'react'
import { IJobDTO } from '../models/IJob'
import { JobCard, DefaultCard } from './listitems'

interface IProps {
    jobs: IJobDTO[] | null
    slug: string
}

export function JobList(p: IProps) {

    if (p.jobs == null)
        return <>"loading ..."</>

    return (
        <div className="flex flex-col gap-3">
            <ul className='divide-y divide-solid divide-gray-300'>
                {p.jobs.map((x, i) => {
                    return (
                        <JobCard
                            slug={p.slug}
                            job={x}
                            key={i}
                        />
                    )
                })}
                <DefaultCard
                    slug={p.slug} />
            </ul>
        </div >
    )
}