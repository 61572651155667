import { IJobDTO } from "../models/IJob";
import { IPropertyDTO } from "../models/IProperty";


interface IApiResponse<T> {
    status: number
    statusText: string
    ok: boolean
    data: T | null
}


// generate all urls
const baseUrl = process.env.REACT_APP_JOBAPI;

/**
 * generate all the needed urls
 */
class Urls {
    getPropertyEndpoint = (slug: string) => baseUrl + "JobListingGroup/" + slug;
    getAllJobsEndpoint = (slug: string) => baseUrl + 'joblisting/' + slug;
    getJobEndpoint = (slug: string, id: number) => baseUrl + 'joblisting/' + slug + '/' + id;
    getSubmitEndpoint = (slug: string) => baseUrl + 'JobApplication/' + slug + '/submit';
}


export class ApiCalls {

    urlGenerator = new Urls();

    fetchProperty(slug: string) {
        return this.makeApiCall<IPropertyDTO>(this.urlGenerator.getPropertyEndpoint(slug), "GET", null);
    }
    fetchJobs(slug: string) {
        return this.makeApiCall<IJobDTO[]>(this.urlGenerator.getAllJobsEndpoint(slug), "GET", null);
    }
    fetchJob(slug: string, id: number) {
        return this.makeApiCall<IJobDTO>(this.urlGenerator.getJobEndpoint(slug, id), "GET", null);
    }
    submitJob(slug: string, body: any) {
        return this.makeApiCall<any>(this.urlGenerator.getSubmitEndpoint(slug), "POST", body);
    }


    /**
     * make an api call and put the response in an object to not loose data,
     * the requeted data is in the data property of the response object
     * @param url url to call
     * @param methode GET POST
     * @param body body as object if needed
     * @returns an object of IApiResponse with the requested data in the data property
     */
    private async makeApiCall<T>(url: string, methode: "GET" | "POST", body: any): Promise<IApiResponse<T>> {
        var fetchData: RequestInit = {
            method: methode,
            mode: 'cors',
        }
        if (body !== undefined && body !== null) {
            fetchData.body = JSON.stringify(body);
            fetchData.headers = {
                'Content-Type': 'application/json'
            }
        }

        var fetchResponse = await fetch(url, fetchData);
        let resp: IApiResponse<T> = {
            status: fetchResponse.status,
            statusText: fetchResponse.statusText,
            ok: fetchResponse.ok,
            data: null
        }

        if (fetchResponse.ok === true) {
            try {
                resp.data = await fetchResponse.json() as T;
            }
            catch (error) {
                console.log('parsing json from response', error);
            }
        }

        return resp;
    }
}

