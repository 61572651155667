import * as React from 'react';
import { useParams } from 'react-router-dom';
//import './../styles.scss';
import './../styles/tailwind.css';
import { Header } from '../components/header';
import { NavMenu } from '../components/navigation';
import { AppStateContext } from '../store/store';
import { JobList } from '../components/joblist';
import { JobDetail } from '../components/jobdetail';
import { EmailForm } from '../components/emailform';
import { CloseButton } from '../components/closebutton';


export function JobPage() {
    const { slug, id } = useParams();
    const context = React.useContext(AppStateContext);

    React.useEffect(() => {
        if (slug === undefined || slug === '') return;
        context.onFetchProperty(slug);
        context.onFetchJobs(slug);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug]);


    if (slug === undefined || slug === '') {
        return (
            <div>Loading ...</div>
        )
    }

    if(context.property === undefined || context.property === null) {
        return (
            <div>Loading ...</div>
        )
    }

    return (
        <div className='jobpage bg-gray-100 pb-5'>
            <Header property={context.property} />
            <NavMenu />
            <div className={'mx-auto w-full flex flex-col md:flex-row gap-5 px-3 md:p-0 md:w-5/6 '+ (id !== undefined ? 'job-selected' : '')}>
                <div className='jobList md:w-1/3 w-full'>
                    <JobList slug={slug} jobs={context.jobs} />
                </div>
                <div className='jobDetail  w-full md:w-2/3 relative'>
                    <CloseButton />
                    <JobDetail />
                    <EmailForm />
                </div>
            </div>
            <footer></footer>
        </div>
    );
}